/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Token } from '../models/token';
import { ProfileDisposableLogin } from '../models/profile-disposable-login';
import { Profile } from '../models/profile';
import { ProfileCreate } from '../models/profile-create';
import { ProfileLogin } from '../models/profile-login';
import { ProfileUpdate } from '../models/profile-update';
import { ProfileRegisterResponse } from '../models/profile-register-response';
import { ProfileRegisterRequest } from '../models/profile-register-request';
import { ProfileResetPassword } from '../models/profile-reset-password';
import { ProfileRestorePassword } from '../models/profile-restore-password';
import { Notifications } from '../models/notifications';
@Injectable({
  providedIn: 'root',
})
class ProfileService extends __BaseService {
  static readonly profileDisposableLoginCreatePath = '/profile/disposable_login';
  static readonly profileListListPath = '/profile/list';
  static readonly profileListCreatePath = '/profile/list';
  static readonly profileLoginCreatePath = '/profile/login';
  static readonly profileLogoutCreatePath = '/profile/logout';
  static readonly profileMeListPath = '/profile/me';
  static readonly profileMePartialUpdatePath = '/profile/me';
  static readonly profileRegisterListPath = '/profile/register';
  static readonly profileRegisterCreatePath = '/profile/register';
  static readonly profileResetPasswordListPath = '/profile/reset_password';
  static readonly profileResetPasswordCreatePath = '/profile/reset_password';
  static readonly profileRestorePasswordCreatePath = '/profile/restore_password';
  static readonly profileReadPath = '/profile/{id}';
  static readonly profileUpdatePath = '/profile/{id}';
  static readonly profilePartialUpdatePath = '/profile/{id}';
  static readonly profileDeletePath = '/profile/{id}';
  static readonly profileDisposableTokenListPath = '/profile/{id}/disposable_token';
  static readonly profileNotificationsCreatePath = '/profile/{id}/notifications';
  static readonly profileResetRegistrationCreatePath = '/profile/{id}/reset_registration';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param data undefined
   */
  profileDisposableLoginCreateResponse(data: ProfileDisposableLogin): __Observable<__StrictHttpResponse<Token>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/profile/disposable_login`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Token>;
      })
    );
  }
  /**
   * @param data undefined
   */
  profileDisposableLoginCreate(data: ProfileDisposableLogin): __Observable<Token> {
    return this.profileDisposableLoginCreateResponse(data).pipe(
      __map(_r => _r.body as Token)
    );
  }

  /**
   * @param params The `ProfileService.ProfileListListParams` containing the following parameters:
   *
   * - `registration_completed`: registration_completed
   *
   * - `ordering`: Ordering
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   *
   * - `is_staff`: is_staff
   *
   * - `ids`: ids
   *
   * - `disabled`: disabled
   */
  profileListListResponse(params: ProfileService.ProfileListListParams): __Observable<__StrictHttpResponse<Array<Profile>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.registrationCompleted != null) __params = __params.set('registration_completed', params.registrationCompleted.toString());
    if (params.ordering != null) __params = __params.set('ordering', params.ordering.toString());
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    if (params.isStaff != null) __params = __params.set('is_staff', params.isStaff.toString());
    if (params.ids != null) __params = __params.set('ids', params.ids.toString());
    if (params.disabled != null) __params = __params.set('disabled', params.disabled.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/profile/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Profile>>;
      })
    );
  }
  /**
   * @param params The `ProfileService.ProfileListListParams` containing the following parameters:
   *
   * - `registration_completed`: registration_completed
   *
   * - `ordering`: Ordering
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   *
   * - `is_staff`: is_staff
   *
   * - `ids`: ids
   *
   * - `disabled`: disabled
   */
  profileListList(params: ProfileService.ProfileListListParams): __Observable<Array<Profile>> {
    return this.profileListListResponse(params).pipe(
      __map(_r => _r.body as Array<Profile>)
    );
  }

  /**
   * @param data undefined
   */
  profileListCreateResponse(data: ProfileCreate): __Observable<__StrictHttpResponse<Profile>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/profile/list`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Profile>;
      })
    );
  }
  /**
   * @param data undefined
   */
  profileListCreate(data: ProfileCreate): __Observable<Profile> {
    return this.profileListCreateResponse(data).pipe(
      __map(_r => _r.body as Profile)
    );
  }

  /**
   * @param data undefined
   */
  profileLoginCreateResponse(data: ProfileLogin): __Observable<__StrictHttpResponse<Token>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/profile/login`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Token>;
      })
    );
  }
  /**
   * @param data undefined
   */
  profileLoginCreate(data: ProfileLogin): __Observable<Token> {
    return this.profileLoginCreateResponse(data).pipe(
      __map(_r => _r.body as Token)
    );
  }
  profileLogoutCreateResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/profile/logout`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }  profileLogoutCreate(): __Observable<null> {
    return this.profileLogoutCreateResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `ProfileService.ProfileMeListParams` containing the following parameters:
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   */
  profileMeListResponse(params: ProfileService.ProfileMeListParams): __Observable<__StrictHttpResponse<Profile>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/profile/me`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Profile>;
      })
    );
  }
  /**
   * @param params The `ProfileService.ProfileMeListParams` containing the following parameters:
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   */
  profileMeList(params: ProfileService.ProfileMeListParams): __Observable<Profile> {
    return this.profileMeListResponse(params).pipe(
      __map(_r => _r.body as Profile)
    );
  }

  /**
   * @param data undefined
   */
  profileMePartialUpdateResponse(data: ProfileUpdate): __Observable<__StrictHttpResponse<ProfileUpdate>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/profile/me`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProfileUpdate>;
      })
    );
  }
  /**
   * @param data undefined
   */
  profileMePartialUpdate(data: ProfileUpdate): __Observable<ProfileUpdate> {
    return this.profileMePartialUpdateResponse(data).pipe(
      __map(_r => _r.body as ProfileUpdate)
    );
  }

  /**
   * @param params The `ProfileService.ProfileRegisterListParams` containing the following parameters:
   *
   * - `token`: регистрационный токен
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   */
  profileRegisterListResponse(params: ProfileService.ProfileRegisterListParams): __Observable<__StrictHttpResponse<ProfileRegisterResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.token != null) __params = __params.set('token', params.token.toString());
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/profile/register`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProfileRegisterResponse>;
      })
    );
  }
  /**
   * @param params The `ProfileService.ProfileRegisterListParams` containing the following parameters:
   *
   * - `token`: регистрационный токен
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   */
  profileRegisterList(params: ProfileService.ProfileRegisterListParams): __Observable<ProfileRegisterResponse> {
    return this.profileRegisterListResponse(params).pipe(
      __map(_r => _r.body as ProfileRegisterResponse)
    );
  }

  /**
   * @param params The `ProfileService.ProfileRegisterCreateParams` containing the following parameters:
   *
   * - `token`: регистрационный токен
   *
   * - `data`:
   */
  profileRegisterCreateResponse(params: ProfileService.ProfileRegisterCreateParams): __Observable<__StrictHttpResponse<Profile>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.token != null) __params = __params.set('token', params.token.toString());
    __body = params.data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/profile/register`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Profile>;
      })
    );
  }
  /**
   * @param params The `ProfileService.ProfileRegisterCreateParams` containing the following parameters:
   *
   * - `token`: регистрационный токен
   *
   * - `data`:
   */
  profileRegisterCreate(params: ProfileService.ProfileRegisterCreateParams): __Observable<Profile> {
    return this.profileRegisterCreateResponse(params).pipe(
      __map(_r => _r.body as Profile)
    );
  }

  /**
   * @param params The `ProfileService.ProfileResetPasswordListParams` containing the following parameters:
   *
   * - `token`: одноразовый токен
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   */
  profileResetPasswordListResponse(params: ProfileService.ProfileResetPasswordListParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.token != null) __params = __params.set('token', params.token.toString());
    if (params.offset != null) __params = __params.set('offset', params.offset.toString());
    if (params.limit != null) __params = __params.set('limit', params.limit.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/profile/reset_password`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `ProfileService.ProfileResetPasswordListParams` containing the following parameters:
   *
   * - `token`: одноразовый токен
   *
   * - `offset`: The initial index from which to return the results.
   *
   * - `limit`: Number of results to return per page.
   */
  profileResetPasswordList(params: ProfileService.ProfileResetPasswordListParams): __Observable<null> {
    return this.profileResetPasswordListResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param data undefined
   */
  profileResetPasswordCreateResponse(data: ProfileResetPassword): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/profile/reset_password`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param data undefined
   */
  profileResetPasswordCreate(data: ProfileResetPassword): __Observable<null> {
    return this.profileResetPasswordCreateResponse(data).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param data undefined
   */
  profileRestorePasswordCreateResponse(data: ProfileRestorePassword): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/profile/restore_password`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param data undefined
   */
  profileRestorePasswordCreate(data: ProfileRestorePassword): __Observable<null> {
    return this.profileRestorePasswordCreateResponse(data).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id A unique integer value identifying this cabinet profile.
   */
  profileReadResponse(id: number): __Observable<__StrictHttpResponse<Profile>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/profile/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Profile>;
      })
    );
  }
  /**
   * @param id A unique integer value identifying this cabinet profile.
   */
  profileRead(id: number): __Observable<Profile> {
    return this.profileReadResponse(id).pipe(
      __map(_r => _r.body as Profile)
    );
  }

  /**
   * @param params The `ProfileService.ProfileUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this cabinet profile.
   *
   * - `data`:
   */
  profileUpdateResponse(params: ProfileService.ProfileUpdateParams): __Observable<__StrictHttpResponse<Profile>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/profile/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Profile>;
      })
    );
  }
  /**
   * @param params The `ProfileService.ProfileUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this cabinet profile.
   *
   * - `data`:
   */
  profileUpdate(params: ProfileService.ProfileUpdateParams): __Observable<Profile> {
    return this.profileUpdateResponse(params).pipe(
      __map(_r => _r.body as Profile)
    );
  }

  /**
   * @param params The `ProfileService.ProfilePartialUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this cabinet profile.
   *
   * - `data`:
   */
  profilePartialUpdateResponse(params: ProfileService.ProfilePartialUpdateParams): __Observable<__StrictHttpResponse<Profile>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/profile/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Profile>;
      })
    );
  }
  /**
   * @param params The `ProfileService.ProfilePartialUpdateParams` containing the following parameters:
   *
   * - `id`: A unique integer value identifying this cabinet profile.
   *
   * - `data`:
   */
  profilePartialUpdate(params: ProfileService.ProfilePartialUpdateParams): __Observable<Profile> {
    return this.profilePartialUpdateResponse(params).pipe(
      __map(_r => _r.body as Profile)
    );
  }

  /**
   * @param id A unique integer value identifying this cabinet profile.
   */
  profileDeleteResponse(id: number): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/profile/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param id A unique integer value identifying this cabinet profile.
   */
  profileDelete(id: number): __Observable<null> {
    return this.profileDeleteResponse(id).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param id undefined
   */
  profileDisposableTokenListResponse(id: string): __Observable<__StrictHttpResponse<Token>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/profile/${encodeURIComponent(String(id))}/disposable_token`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Token>;
      })
    );
  }
  /**
   * @param id undefined
   */
  profileDisposableTokenList(id: string): __Observable<Token> {
    return this.profileDisposableTokenListResponse(id).pipe(
      __map(_r => _r.body as Token)
    );
  }

  /**
   * @param params The `ProfileService.ProfileNotificationsCreateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  profileNotificationsCreateResponse(params: ProfileService.ProfileNotificationsCreateParams): __Observable<__StrictHttpResponse<Notifications>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.data;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/profile/${encodeURIComponent(String(params.id))}/notifications`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Notifications>;
      })
    );
  }
  /**
   * @param params The `ProfileService.ProfileNotificationsCreateParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `data`:
   */
  profileNotificationsCreate(params: ProfileService.ProfileNotificationsCreateParams): __Observable<Notifications> {
    return this.profileNotificationsCreateResponse(params).pipe(
      __map(_r => _r.body as Notifications)
    );
  }

  /**
   * @param id undefined
   */
  profileResetRegistrationCreateResponse(id: string): __Observable<__StrictHttpResponse<Profile>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/profile/${encodeURIComponent(String(id))}/reset_registration`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Profile>;
      })
    );
  }
  /**
   * @param id undefined
   */
  profileResetRegistrationCreate(id: string): __Observable<Profile> {
    return this.profileResetRegistrationCreateResponse(id).pipe(
      __map(_r => _r.body as Profile)
    );
  }
}

module ProfileService {

  /**
   * Parameters for profileListList
   */
  export interface ProfileListListParams {

    /**
     * registration_completed
     */
    registrationCompleted?: string;

    /**
     * Ordering
     */
    ordering?: 'created_at' | '-created_at' | 'updated_at' | '-updated_at';

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Number of results to return per page.
     */
    limit?: number;

    /**
     * is_staff
     */
    isStaff?: string;

    /**
     * ids
     */
    ids?: string;

    /**
     * disabled
     */
    disabled?: string;
  }

  /**
   * Parameters for profileMeList
   */
  export interface ProfileMeListParams {

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Number of results to return per page.
     */
    limit?: number;
  }

  /**
   * Parameters for profileRegisterList
   */
  export interface ProfileRegisterListParams {

    /**
     * регистрационный токен
     */
    token: string;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Number of results to return per page.
     */
    limit?: number;
  }

  /**
   * Parameters for profileRegisterCreate
   */
  export interface ProfileRegisterCreateParams {

    /**
     * регистрационный токен
     */
    token: string;
    data: ProfileRegisterRequest;
  }

  /**
   * Parameters for profileResetPasswordList
   */
  export interface ProfileResetPasswordListParams {

    /**
     * одноразовый токен
     */
    token: string;

    /**
     * The initial index from which to return the results.
     */
    offset?: number;

    /**
     * Number of results to return per page.
     */
    limit?: number;
  }

  /**
   * Parameters for profileUpdate
   */
  export interface ProfileUpdateParams {

    /**
     * A unique integer value identifying this cabinet profile.
     */
    id: number;
    data: ProfileCreate;
  }

  /**
   * Parameters for profilePartialUpdate
   */
  export interface ProfilePartialUpdateParams {

    /**
     * A unique integer value identifying this cabinet profile.
     */
    id: number;
    data: ProfileCreate;
  }

  /**
   * Parameters for profileNotificationsCreate
   */
  export interface ProfileNotificationsCreateParams {
    id: string;
    data: Notifications;
  }
}

export { ProfileService }
